import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';

// Define breakpoints in pixels
const breakpoints = [0, 768, 1070]; // Updated the third breakpoint from 1024 to 1070

// Styled component outside of Flex
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.componentWidth}px;
  height: ${(props) => 
    typeof props.height === 'number' ? `${props.height}px` : props.height};  padding-left: ${(props) => (props.gutters ? props.gutterWidth / 2 : 0)}px;
  padding-right: ${(props) => (props.gutters ? props.gutterWidth / 2 : 0)}px;
  flex-shrink: 0;
`;

const Flex = ({ col, span, height, gutters = true, container = false, children, style }) => {
  const { gutterWidth } = useContext(AppContext);

  const getBreakpointIndex = (width) => {
    let index = 0;
    for (let i = 0; i < breakpoints.length; i++) {
      if (width >= breakpoints[i]) {
        index = i;
      } else {
        break;
      }
    }
    return index;
  };

  const [breakpointIndex, setBreakpointIndex] = useState(() =>
    typeof window !== 'undefined' ? getBreakpointIndex(window.innerWidth) : 0
  );

  const colCurrent = useMemo(() => {
    if (Array.isArray(col)) {
      return col[breakpointIndex] !== undefined ? col[breakpointIndex] : col[col.length - 1];
    }
    return col;
  }, [col, breakpointIndex]);

  const spanCurrent = useMemo(() => {
    if (Array.isArray(span)) {
      return span[breakpointIndex] !== undefined ? span[breakpointIndex] : span[span.length - 1];
    }
    return span;
  }, [span, breakpointIndex]);

  const heightCurrent = useMemo(() => {
    if (Array.isArray(height)) {
      return height[breakpointIndex] !== undefined ? height[breakpointIndex] : height[height.length - 1];
    }
    return height;
  }, [height, breakpointIndex]);

  const [componentWidth, setComponentWidth] = useState(() => {
    if (typeof window === 'undefined') return 0;
    const viewportWidth = window.innerWidth;
    if (container) {
      return viewportWidth;
    }

    if (colCurrent === 0 || spanCurrent === 0) {
      return 0;
    }

    const totalGutterWidth = (colCurrent + 1) * gutterWidth;
    const calculatedColumnWidth = (viewportWidth - totalGutterWidth) / colCurrent;
    return (calculatedColumnWidth + gutterWidth) * spanCurrent;
  });

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const newIndex = getBreakpointIndex(viewportWidth);
      if (newIndex !== breakpointIndex) {
        setBreakpointIndex(newIndex);
      }

      if (container) {
        setComponentWidth(viewportWidth);
      } else {
        if (colCurrent === 0 || spanCurrent === 0) {
          setComponentWidth(0);
          return;
        }
        const totalGutterWidth = (colCurrent + 1) * gutterWidth;
        const calculatedColumnWidth = (viewportWidth - totalGutterWidth) / colCurrent;
        setComponentWidth((calculatedColumnWidth + gutterWidth) * spanCurrent);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpointIndex, colCurrent, spanCurrent, gutterWidth, container]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const viewportWidth = window.innerWidth;
    if (container) {
      setComponentWidth(viewportWidth);
    } else {
      if (colCurrent === 0 || spanCurrent === 0) {
        setComponentWidth(0);
        return;
      }
      const totalGutterWidth = (colCurrent + 1) * gutterWidth;
      const calculatedColumnWidth = (viewportWidth - totalGutterWidth) / colCurrent;
      setComponentWidth((calculatedColumnWidth + gutterWidth) * spanCurrent);
    }
  }, [colCurrent, spanCurrent, gutterWidth, container]);

  return (
    <StyledDiv
      componentWidth={componentWidth}
      height={heightCurrent}
      gutters={gutters && spanCurrent !== 0}
      gutterWidth={gutterWidth}
      style={style}
    >
      {children}
    </StyledDiv>
  );
};

export default Flex;