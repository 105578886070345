import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // Arrow function to determine the viewport Type
  const getViewportType = (width) => {
    if (width < 768) return 0;
    if (width >= 768 && width < 1070) return 1;
    return 2;
  };

  // State to hold the viewportType value
  const [viewportType, setViewportType] = useState(() =>
    typeof window !== 'undefined' ? getViewportType(window.innerWidth) : 0
  );

  // State to hold the gutterWidth value
  const [gutterWidth, setGutterWidth] = useState(() => {
    const type = typeof window !== 'undefined' ? getViewportType(window.innerWidth) : 0;
    return type === 1 ? 24 : 16;
  });

  useEffect(() => {
    const handleResize = () => {
      setViewportType(getViewportType(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (viewportType === 1) {
      setGutterWidth(24);
    } else {
      setGutterWidth(16);
    }
  }, [viewportType]);

  return (
    <AppContext.Provider value={{ gutterWidth, viewportType }}>
      {children}
    </AppContext.Provider>
  );
};