import React, { useEffect, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Button2 from 'button2';
import { 
  FaLinkedinIn, FaTwitter, FaInstagram,
  FaTree, FaLeaf, FaCheckCircle
} from 'react-icons/fa';
import { GiThreeLeaves, GiCurledLeaf } from 'react-icons/gi';

import { ReactComponent as EcoLogo } from './assets/Eco logo hi res.svg';
import { ReactComponent as EcoLogoWhite } from './assets/Eco logo hi res white.svg';
import TopBannerImg from './assets/banner-static-image.png';
import DashboardImage from './assets/ecosphere_dashboard.png';
import PreviewEcoScan from './assets/ecoscan-preview-asset.png';
import PreviewEcoSnap from './assets/ecosnap-preview-asset.png';
import PreviewEcoSphere from './assets/ecosphere-preview-asset.png';
import LogoEcoScan from './assets/ecoScan-hi-res.png';
import LogoEcoSnap from './assets/ecoSnap-hi-res.png';
import LogoEcoDocs from './assets/ecoDocs_hi_res.svg';
import LogoEcoSphere from './assets/ecoSphere-hi-res.png';
import TopVideo from './assets/banner-vid-720p.webm';
import './sass/style.js';

import EcoMain from './assets/EcoMain.png';
import EcoIpadAppPage from './assets/EcoIpadAppPage.svg';
import EcoIpadProjects from './assets/EcoIpadProjects.svg';
import EcoIpadProfile from './assets/EcoIpadProfile.svg';
import EcoProjects from './assets/EcoProjects.png';
import EcoProfile from './assets/EcoProfile.svg';
import EcoSphereLogo from './assets/EcoSphereLogo.png';
import EcoSnapLogo from './assets/EcoSnapLogo.svg';
import EcoGallery from './assets/EcoGallery.svg';
import EcoPam from './assets/EcoPam.svg';
import EcoCamera from './assets/EcoCamera.svg';
import EcoIpadPam from './assets/EcoIpadPam.svg';
import EcoScanLogo from './assets/EcoScanLogo.png';
import EcoIpadGallery from './assets/EcoIpadGallery.svg';
import { ReactComponent as EcoEye } from './assets/EcoEye.svg';
import { ReactComponent as EcoEyeOpen } from './assets/EcoEyeOpen.svg';
import EcoLogoShort from './assets/EcoLogoShort.svg';

function App() {
  const loremIpsum = 'The strength of EcoAnalytics™ lies in its powerful data visualization tools, which transform raw data into dynamic maps, graphs, and other visual aids. These interactive visuals offer a more intuitive and engaging way for users to explore and comprehend the intricacies of ecological and environmental data. By presenting information in a clear and accessible format, EcoAnalytics™ allows stakeholders to better understand the relationships between various factors, identify areas of concern, and evaluate the impact of proposed solutions.';
  const words = loremIpsum.split(' ');
  const [x, setX] = useState(0)
  const [newX, setnewX] = useState(0)
  const [carouselPos, setCarouselPos] = useState(0)
  const [resetPass, setResetPass] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [resetSuccess, setResetSuccess] = useState("waiting")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('c')) {
      setResetPass(true)
    }
  }, [])

  const handleTouchCarousel = (e) => {
    const curX = e.touches[0].clientX
    setnewX(curX)
  }

  const handleCarouselEnd = (e) => {
    if (newX < x & carouselPos < 3 & newX !== '') {
      setCarouselPos(prev => prev + 1)
    } else if (newX > x & carouselPos > 0 & newX !== '') {
      setCarouselPos(prev => prev - 1)
    }
    setX('')
    setnewX('')
  }

  const handleReset = async () => {
    const query = new URLSearchParams(window.location.search);
    if (password !== confirmPassword) {
      setShowError(true)
      setErrorMessage('Passwords do not match')
      return
    }
    const code = query.get('c')
    const email = query.get('e')
    const resetData = await fetch(`${process.env.REACT_APP_ECOSPHERE_SERVER}/api/user/forgot`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        code: code,
        userEmail: email,
        password: password
      })
    })
    if (!resetData.ok) {
      setShowError(true)
      setErrorMessage('Incorrect Password')
      return
    } else {
      setPassword('')
      setConfirmPassword('')
      setShowError(false)
      setErrorMessage('Password Reset Successfully')
      return
    }
  }


  const circles = ['○', '○', '○', '○'];
  const filledCircle = '●';

  return (
    <>{!resetPass ?
    <div>
      <div className='home-top-container'>
        <video autoPlay loop muted playsInline>
          <source src={TopVideo} type='video/webm' />
        </video>
        <div className='home-static-banner'>
          <img src={TopBannerImg} />
        </div>
        <div className='home-top-content'>
          <div className='home-menu-container'>
            <div className='home-menu-logo'>
              <EcoLogo />
            </div>
            <div style={{flex: '1'}}></div>
            <div className='home-buttons-wrapper'>
              <div className='home-menu-buttons'>
                <div className="learn-button" onClick={() => window.location = 'https://www.youtube.com/@EcoAnalytics-EcoScan'}>EcoLearning</div>
              </div>
              <div className='home-menu-buttons'>
                <div className="manage-button" onClick={() => {window.location = process.env.REACT_APP_ECOSPHERE_FRONT}}>Manage Subscriptions</div>
              </div>
            </div>
          </div>
          <div className='home-banner-container'>
            <div className='home-banner-title'>
              <h1>A Revolution in Ecological and Environmental Data Management</h1>            
            </div>
            <div className='home-banner-description'>
              <p>Empower researchers, policymakers, and private organizations by allowing them to make more informed decisions and take more effective action to address environmental challenges.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='home-main-wrapper'>
        <Controller>
          <div className='home-preview-container'>
            <div>
              <GiCurledLeaf color='#98c93c' />
              <span style={{ paddingLeft: '0.5em', color: '#93ddff' }}>Centralize data</span>
            </div>
            <h1>Organize All Your Project Assets in One Place.</h1>
            <div className='home-preview-image'>
              <Scene triggerHook='onLeave' duration='50%' pin={true} offset={(window.innerHeight / 1275) * 1380}>
                {(progress) => (
                  <Tween to={{ transform: `rotateX(${progress * -60}deg)` }}>
                    <img src={EcoMain} />
                  </Tween>
                )}
              </Scene>
            </div>
          </div>
          <div className='home-info-container'>
            <Scene triggerHook='onLeave' pin={true} duration='40%'>
              {(progress) => (
                <div style={{paddingTop: `${(window.innerHeight / 1275) * 20}em`}}>
                  {words.map((word, index) => (
                    <Tween to={{ opacity: progress * 3 >= ( (3 * index + 1) / (words.length)) ? 1 : .2 }} key={index}>
                      <span className='home-info-text'>{word + ' '}</span>
                    </Tween>
                  ))}
                </div>
              )}
            </Scene>
          </div>
            {/* <Scene pin={true} duration={4000} indicators={true}> */}
            <Scene triggerHook='onLeave' pin={window.innerWidth > 1069 ? true : false} duration='30%'>
              {(progress) => (
                <div onTouchStart={(e) => {setX(e.touches[0].clientX)}} onTouchMove={(e) => handleTouchCarousel(e)} onTouchEnd={(e) => {handleCarouselEnd(e)}} className='home-product-container'>
                  <div>
                    <GiCurledLeaf color='#98c93c' />
                    <span style={{ paddingLeft: '0.5em', color: '#93ddff' }}>Our products</span>
                  </div>
                  <h1>Different Tools to Suit Different Needs.</h1>
                  <div className='home-carousel-dots'>
                    {circles.map((circle, index) => (
                      <span key={index} style={{ fontSize: '0.5em', padding: '5px' }}>
                        {carouselPos === index ? filledCircle : circle}
                      </span>
                    ))}
                  </div>
                  <Tween to={{x: window.innerWidth > 1069 ? `${Math.round((progress * -3072) / 1024) * 1024}px` : `${(-carouselPos) * (366)}px`}}>
                    <div style={{width: window.innerWidth > 1069 ? '4096px' : ((366) * 4 ), display: 'flex'}}>
                      <div className='home-carousel-product'>
                        <div style={{ width: window.innerWidth > 1069 ? '605px' : '285px', padding: '2em' }}>
                          <img src={LogoEcoSphere} className='carousel-product-logo' />
                          <div className='description-text'>
                            <p>
                              ecoSphere™ is a robust environmental management application 
                              designed to streamline the organization and tracking of 
                              various assets related to environmental projects, from 
                              California Rapid Assessment Method (CRAM) assessments, 
                              photo stations, pertinent documentation, survey forms, 
                              and much more. The platform offers an effective way to integrate, 
                              manage and access a vast array of project-specific resources 
                              at a glance.
                            </p>
                            <p>
                              Alongside asset management, ecoSphere™ also serves as a 
                              collaboration hub for project teams, facilitating efficient 
                              team management by keeping track of licenses, enhancing 
                              communication, and providing tools that foster seamless 
                              collaboration, ultimately propelling projects towards their 
                              successful completion.
                            </p>
                          </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <img src={EcoProjects} className='carousel-product-preview'/>
                          <img src={EcoProfile} style={{aspectRatio: '9/25'}} className='carousel-product-preview'/>
                        </div>
                      </div>
                      <div className='home-carousel-product'>
                        <div style={{ width: window.innerWidth > 1069 ? '405px' : '285px', padding: '2em' }}>
                          <img src={LogoEcoScan} className='carousel-product-logo' />
                          <div className='description-text'>
                            <p>
                              ecoScan™ cuts hours off the current effective time it takes to assess 
                              a wetland area and is designed to evaluate the condition of the 
                              wetland based on its landscape setting, hydrology, physical
                              structure, and biological structure.
                            </p>
                            <p>
                              Because the methodology is 
                              standardized for over seven types of wetlands, ecological 
                              condition scores can be compared at the local, regional, and 
                              statewide landscape scales. It can also be used to assess the 
                              performance of compensatory mitigation projects and restoration 
                              projects.
                            </p>
                          </div>
                        </div>
                        <div>
                          <img src={PreviewEcoScan} style={{width: window.innerWidth > 1069 ? '30em' : '19em'}}/>
                        </div>
                      </div>
                      <div className='home-carousel-product' style={{height: window.innerWidth > 1069 ? '60vh' : '150vh'}}>
                        <div style={{ width: window.innerWidth > 1069 ? '605px' : '285px', padding: '2em' }}>
                          <img src={LogoEcoSnap} className='carousel-product-logo'/>
                          <div className='description-text'>
                            <p>
                              ecoSnap™ is a comprehensive application that empowers users to 
                              manage and chronicle project site photos over time by 
                              establishing a comprehensive photographic record of the site 
                              from all cardinal and subcardinal directions. Providing a 
                              unique and chronological view of specific locations, it allows 
                              for tracking changes in the environment or project progression 
                              in a highly organized manner. 
                            </p>
                            <p>
                              The app's innovative feature to 
                              generate 'photo plates,' which are PDF exports encapsulating 
                              collections of photo stations for specific projects, transforms 
                              the way one visualizes, shares, and understands spatial data 
                              over time, thereby proving invaluable for environmental 
                              studies, construction projects, and similar endeavours.
                            </p>
                          </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <img src={EcoGallery} className='carousel-product-preview'/>
                          <img src={EcoPam} className='carousel-product-preview'/>
                        </div>
                        <div>
                          <img src={EcoCamera} style={{width: window.innerWidth > 1069 ? '10em' : '12em', aspectRatio: '19.5/9', marginTop: '50px'}}  className='carousel-product-preview'/>
                        </div>
                      </div>
                      <div className='home-carousel-product' style={{height: window.innerWidth > 1069 ? '60vh' : '150vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1e1e1e'}}>
                        <div className='ecoDocs-border' style={{height: '80%', width: '80%'}}>
                          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', backgroundColor: '#1e1e1e', borderRadius: 25, position: 'relative'}}>
                            <img style={{top: window.innerWidth > 1069 ? '-100px' : '-75px', position: 'absolute', backgroundColor: '#1e1e1e'}}src={LogoEcoDocs} className='ecoLogo'/>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '30%', width: '60%', backgroundColor: '#1e1e1e'}}>
                              <div style={{color: "#CCCCCC", fontSize: 16}}>Closed Alpha</div>
                              <div style={{color: "#F1F1F1", fontSize: 32}}>COMING SOON</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tween>
                </div>
              )}
            </Scene>
            <Scene pin={window.innerWidth > 1069 ? true : false} triggerHook='onLeave' duration={'60%'}>
              {(progress) => (
                <div className='home-pricing-container'>
                  <div>
                    <GiCurledLeaf color='#98c93c' />
                    <span style={{ paddingLeft: '0.5em', color: '#93ddff' }}>Pricing plans</span>
                  </div>
                  <h1>Flexible Pricing to Fit Scaling Teams.</h1>
                    <img src={EcoSnapLogo} className='ecoLogo'/>
                      <div className='pricing-container-wrapper'>
                        <Timeline totalProgress={window.innerWidth > 1069 ? progress : 1} paused>
                          <Tween from={{ y: '100vh' }} to={{ y: '0vh' }} stagger={1}>
                          <div className='tier-container' style={{ borderColor: '#97dbfb'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 1</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 1 license</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{ marginTop: '1em'}}>
                                <span className='tier-price'>$9.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#76b1cc'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                              {/* <div>
                                <span style={{fontWeight: 'bold'}}>For independent contractors</span>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>1 user</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>10GB ecoSnap storage</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>60 exports per month</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Customizable reports</span>
                                </div>
                              </div> */}
                            </div>

                            <div className='tier-container' style={{ borderColor: '#91c5b7'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 2</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 2-5 licenses</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{marginTop: '1em'}}>
                                <span className='tier-price'>$19.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#6ead9c'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                            </div>

                            <div className='tier-container' style={{ borderColor: '#a8d25b'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 3</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 6 licenses</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{marginTop: '1em'}}>
                                <span className='tier-price'>$49.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#8bad4b'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                            </div>
                            {/* <div className='pricing-container-element'>
                              <div>
                                <GiThreeLeaves color='#98c93c' size='1.5em'/>
                                <span style={{paddingLeft: '0.5em'}}>Pro</span>
                              </div>
                              <div style={{marginBottom: '2em'}}>
                                <span style={{fontSize: '3em'}}>$69.95</span>
                                <span>/user/mo</span>
                              </div>
                              <div>
                                <span style={{fontWeight: 'bold'}}>For growing teams</span>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>2 - 5 users</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>50GB ecoSnap storage</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Unlimited exports</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Customizable reports</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Collaboration tools</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Beta feature access</span>
                                </div>
                              </div>
                            </div>
                            <div className='pricing-container-element'>
                              <div>
                                <FaTree color='#98c93c' size='1.5em'/>
                                <span style={{paddingLeft: '0.5em'}}>Enterprise</span>
                              </div>
                              <div style={{marginBottom: '2em'}}>
                                <span style={{fontSize: '3em'}}>$89.95</span>
                                <span>/user/mo</span>
                              </div>
                              <div>
                                <span style={{fontWeight: 'bold'}}>For large, established firms</span>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>6+ users</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Flex ecoSnap storage</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Unlimited exports</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Customizable reports</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Collaboration tools</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Beta feature access</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Team software training</span>
                                </div>
                              </div>
                            </div> */}

                          </Tween>
                        </Timeline>
                      </div>

                      <div className='divider'></div>

                      <img src={EcoScanLogo} className='ecoLogo'/>
                      <div className='pricing-container-wrapper'>
                        <Timeline totalProgress={window.innerWidth > 1069 ? progress : 1} paused>
                          <Tween from={{ y: '100vh' }} to={{ y: '0vh' }} stagger={1}>
                          <div className='tier-container' style={{ borderColor: '#97dbfb'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 1</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 1 license</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{ marginTop: '1em'}}>
                                <span className='tier-price'>$49.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#76b1cc'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                              {/* <div>
                                <span style={{fontWeight: 'bold'}}>For independent contractors</span>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>1 user</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>10GB ecoSnap storage</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>60 exports per month</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Customizable reports</span>
                                </div>
                              </div> */}
                            </div>

                            <div className='tier-container' style={{ borderColor: '#91c5b7'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 2</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 2-5 licenses</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{marginTop: '1em'}}>
                                <span className='tier-price'>$69.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#6ead9c'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                            </div>

                            <div className='tier-container' style={{ borderColor: '#a8d25b'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 3</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 6 licenses</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{marginTop: '1em'}}>
                                <span className='tier-price'>$99.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#8bad4b'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                            </div>
                          </Tween>
                        </Timeline>
                      </div>

                      <div className='divider'></div>

                      <img src={LogoEcoDocs} className='ecoLogo'/>
                      <div className='pricing-container-wrapper'>
                        <Timeline totalProgress={window.innerWidth > 1069 ? progress : 1} paused>
                          <Tween from={{ y: '100vh' }} to={{ y: '0vh' }} stagger={1}>
                          <div className='tier-container' style={{ borderColor: '#97dbfb'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 1</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 1 license</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{ marginTop: '1em'}}>
                                <span className='tier-price'>$19.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#76b1cc'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                              {/* <div>
                                <span style={{fontWeight: 'bold'}}>For independent contractors</span>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>1 user</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>10GB ecoSnap storage</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>60 exports per month</span>
                                </div>
                                <div className='pricing-element-feature'>
                                  <FaCheckCircle color='#93ddff'/>
                                  <span>Customizable reports</span>
                                </div>
                              </div> */}
                            </div>

                            <div className='tier-container' style={{ borderColor: '#91c5b7'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 2</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 2-5 licenses</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{marginTop: '1em'}}>
                                <span className='tier-price'>$29.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#6ead9c'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                            </div>

                            <div className='tier-container' style={{ borderColor: '#a8d25b'}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '0.05em solid #cccccc', paddingBottom: '0.75em'}}>
                                <span className='tier-text'>Tier 3</span>
                                <span className='tier-italic' style={{marginTop: '0.5em'}}>For purchase of 6 licenses</span>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1.75em'}}>
                                <span className='tier-italic'>Starting at</span>
                              </div>
                              <div style={{marginTop: '1em'}}>
                                <span className='tier-price'>$49.99</span>
                                <span className='tier-italic' style={{fontSize: '0.85em'}}>/mo</span>
                              </div>
                              <div style={{ marginTop: '0.9em', alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <span className='tier-italic' style={{fontSize: '0.75em'}}>*Storage subject to additional cost.</span>
                              </div>
                              {/* <div className='tier-button' style={{backgroundColor: '#8bad4b'}}>
                                <span className='tier-button-text'>Purchase</span>
                              </div> */}
                            </div>
                          </Tween>
                        </Timeline>
                      </div>
                </div>
              )}
            </Scene>
          <div className='home-footer-container'>
            <div className='home-footer-top'>
              <div className='footer-top-brand'>
                <EcoLogoWhite />
                <div><span>Experience the next generation of Environmental Resource Management</span></div>
                <div><Button2 frontColor1='#37393d' frontColor2='#292b2e' backColor='#2d2f33' text='Log In' customClass='footer-login-button' handler={() => {window.location = process.env.REACT_APP_ECOSPHERE_FRONT}}/></div>
              </div>
              <div className='footer-top-nav'>
                <span style={{fontWeight: 'bold'}}>Legals</span>
                <span>Terms of Services</span>
                <span>Privacy Policy</span>
              </div>
            </div>
            <div className='home-footer-bottom'>
              <div className='footer-bottom-copyright'>
                &copy; {new Date().getFullYear()} EcoAnalytics™, Inc. All rights reserved.
              </div>
              <div className='footer-bottom-socials'>
                <FaInstagram />
                <FaLinkedinIn />
                <FaTwitter />
              </div>
            </div>
          </div>
        </Controller>
      </div>
    </div>
    : 

    <div className='login-main-container'>
      <div className='login-form-area'>
        <div className='login-form-wrapper'>
          <div className='login-form-logo'>
            <EcoLogo style={{ width: '100%', height: '100%' }}/>
          </div>
          <div className='login-password-input' >
            <p className='login-black-text'>New Password*</p>
            <div className='login-input' style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
              <input placeholder='Password' type={showPassword ? 'text' : 'password'} style={{borderWidth: '0px'}}
              onChange={(e) => setPassword(e.target.value)}/>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EcoEyeOpen /> : <EcoEye />}
              </div>
            </div>
          </div>
          <div className='login-confirm-password-input' >
            <p className='login-black-text'>Confirm New Password*</p>
            <div className='login-input' style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
              <input placeholder='Confirm Password' type={showConfirmPassword ? 'text' : 'password'} style={{borderWidth: '0px'}}
              onChange={(e) => setConfirmPassword(e.target.value)}/>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <EcoEyeOpen /> : <EcoEye />}
              </div>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginTop: '8px'}}>
            <div className="login-link" style={{color: showError ? '#b00020' : '#7DA632', fontSize: '0.8em'}}>
              {errorMessage}
            </div>
          </div>
          {/* <div className='login-remember-container'>
            <input className='login-remember-chkbox' type='checkbox' />
            <span> Remember Me</span>
          </div> */}
          {/* <Button2 frontColor1="#92dcff" frontColor2="#94eaff" backColor="#7ac6e9" text="Log In" customClass="signup-signupsubmit-btn-trial" handler={() => handleLogin()} /> */}
          <div className='login-btn-wrapper'>
            <div className='login-button' onClick={() => handleReset()}>Reset</div>
          </div>
          <div className='login-footer-div login-black-text'>
            <span style={{fontSize: '0.85em'}}>
              Copyright &copy; {new Date().getFullYear()} EcoAnalytics™ | All Rights Reserved | <a className="login-link" href='#'> Privacy Policy</a>
            </span>
          </div>
        </div>
      </div>
    </div>}</>
  );
}

export default App;
