import React, { useState, useContext, useRef } from 'react';
import { AppContext } from './context/AppContext';
import Flex from './components/Flex';
import ProductCard from './components/ProductCard';
import PricingCard from './components/PricingCard';

import { GiCurledLeaf } from 'react-icons/gi';
import BannerStatic from './assets/banner-static-image.png';
import BannerVideo from './assets/banner-vid-720p.webm';

import { ReactComponent as EcoLogo } from './assets/Eco logo hi res.svg';
import { ReactComponent as EcoDown } from './assets/EcoDown.svg';
import { ReactComponent as EcoInsta } from './assets/EcoInsta.svg';
import { ReactComponent as EcoLI } from './assets/EcoLI.svg';
import { ReactComponent as EcoMessage } from './assets/EcoMessage.svg';
import { ReactComponent as EcoNI } from './assets/EcoNI.svg';
import { ReactComponent as EcoHR } from './assets/EcoHR.svg';
import { ReactComponent as EcoWBENC } from './assets/EcoWBENC.svg';

import { ReactComponent as EcoBackGray } from './assets/EcoBackGray.svg';
import { ReactComponent as EcoForwardGray } from './assets/EcoForwardGray.svg';

import { ReactComponent as EcoDocsLogo } from './assets/EcoDocsLogo.svg';
import FooterBG from './assets/FooterBG.svg';
import { ReactComponent as EcoMain1 } from './assets/EcoMain1.svg';
import { ReactComponent as EcoMain2 } from './assets/EcoMain2.svg';
import { ReactComponent as EcoMain3 } from './assets/EcoMain3.svg';
import { ReactComponent as EcoIpadMain1 } from './assets/EcoIpadMain1.svg';
import { ReactComponent as EcoIpadMain2 } from './assets/EcoIpadMain2.svg';
import { ReactComponent as EcoIpadMain3 } from './assets/EcoIpadMain3.svg';
import { ReactComponent as EcoIpadMain4 } from './assets/EcoIpadMain4.svg';
import { ReactComponent as EcoDocsIpadLogo } from './assets/EcoDocsIpadLogo.svg';
import EcoBackground from './assets/EcoBackground.svg';
import EcoIpadBackground from './assets/EcoIpadBackground.svg';
import styled from 'styled-components';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

const AppV2 = () => {
  const appName = ['ecoSphere', 'ecoScan', 'ecoSnap', 'ecoDocs'];
  const loremIpsum = 'The strength of EcoAnalytics™ lies in its powerful data visualization tools, which transform raw data into dynamic maps, graphs, and other visual aids. These interactive visuals offer a more intuitive and engaging way for users to explore and comprehend the intricacies of ecological and environmental data. By presenting information in a clear and accessible format, EcoAnalytics™ allows stakeholders to better understand the relationships between various factors, identify areas of concern, and evaluate the impact of proposed solutions.';
  const words = loremIpsum.split(' ');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isOrganizationFocused, setIsOrganizationFocused] = useState(false);
  const [isRoleFocused, setIsRoleFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isMessageFocused, setIsMessageFocused] = useState(false);
  const [formFirstName, setFormFirstName] = useState('');
  const [formLastName, setFormLastName] = useState('');
  const [formCompany, setFormCompany] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [scanPrices, setShowScanPrices] = useState(false);
  const [snapPrices, setShowSnapPrices] = useState(false);
  const [docsPrices, setShowDocsPrices] = useState(false);

  const { viewportType } = useContext(AppContext);

  const handleFormUpdate = (e) => {
    
  }

  const submitToSalesforce = (event) => {
    event.preventDefault();
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D610000007xQc');

    const addHiddenField = (name, value) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', name);
      input.setAttribute('value', value);
      form.appendChild(input);
    };

    addHiddenField('oid', '00D610000007xQc');
    addHiddenField('retURL', 'https://ecoanalytics.com/');
    // addHiddenField('debug', '1');
    // addHiddenField('debugEmail', 'kevinc@hanaresources.com');

    addHiddenField('first_name', formFirstName);
    addHiddenField('last_name', formLastName);
    addHiddenField('company', formCompany);
    addHiddenField('title', formTitle);
    addHiddenField('email', formEmail);
    addHiddenField('00N61000005ODHsEAO', formMessage);

    addHiddenField('lead_source', "Website");
    addHiddenField('00N4M00000IQEaq', "Eco");
    addHiddenField('recordType', "012QP000001HMGT");
    addHiddenField('00NQP000000ws6T', "Website (Eco)");
    
    document.body.appendChild(form);
    form.submit();
  };

  const handleForward = () => {
    if (currentIndex === appName.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentIndex === 0) {
      setCurrentIndex(appName.length - 1);
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleCentralizeDataScroll = () => {
    const target = document.getElementById('centralize-data');
    
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleSnapPricingScroll = () => {
    const target = document.getElementById('snap-pricing');
    
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleScanPricingScroll = () => {
    const target = document.getElementById('scan-pricing');
    
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleDocsPricingScroll = () => {
    const target = document.getElementById('docs-pricing');
    
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleContactScroll = () => {
    const target = document.getElementById('contact-us');
    
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const styles = {
    landingPageButton: {
      height: viewportType === 0 ? '32px' : (viewportType === 1 ? '36px' : '32px'),
      width: viewportType === 0 ? '130px' : (viewportType === 1 ? '162px' : '100%'),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      clipPath: viewportType === 0 ? 'inset(0 round 4px)' : (viewportType === 1 ? 'inset(0 round 6px)' : 'inset(0 round 5px)'),
      fontSize: viewportType === 0 ? '13px' : (viewportType === 1 ? '15px' : '13.75px'),
      color: '#f1f1f1',
      fontWeight: 500,
      fontFamily: 'Poppins',
      cursor: 'pointer',
    },

    blueText: {
      color: '#93ddff',
      fontFamily: 'Poppins',
      fontSize: viewportType === 0 ? '17px' : (viewportType === 1 ? '20px' : '19.5px'),
      fontWeight: 500,
      marginLeft: '8px',
    },

    sectionText: {
      color: '#f1f1f1',
      fontFamily: 'Poppins',
      fontSize: viewportType === 0 ? '18px': (viewportType === 1 ? '22px' : '20.5px'),
      fontWeight: 600,
      textAlign: 'center',
    },

    description: {
      color: '#f1f1f1',
      fontWeight: 500,
      fontSize: viewportType === 0 ? '16px' : (viewportType === 1 ? '20px' : '18.5px'),
      fontFamily: 'Manrope',
    },

    pcText: {
      color: '#000000',
      fontFamily: 'Didact Gothic',
      fontSize: viewportType === 0 ? '15px' : (viewportType === 1 ? '16px' : '15.5px'),
      fontWeight: 300,
      marginTop: viewportType === 2 ? '28px' : '28px',
    },

    line: {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#3f3f3f',
      width: '100%',
    },

    inputLabel: {
      color: '#f1f1f1',
      fontWeight: 450,
      fontSize: viewportType === 0 ? '14px' : '15px',
      fontFamily: 'Manrope',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: viewportType === 0 ? '8px' : '12px',
    },


    inputStyle: {
      height: viewportType === 0 ? '42px' : '43px',
      width: '100%',
      backgroundColor: 'rgba(63, 63, 63, 0.85)',
      alignItems: 'center',
      borderRadius: '5px',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    inputText: {
      borderWidth: '0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      outline: 'none',
      boxShadow: 'none',
      color: '#f1f1f1',
      fontWeight: 450,
      fontSize: viewportType === 0 ? '13.5px' : (viewportType === 1 ? '14px' : '13.5px'),
      fontFamily: 'Manrope',
      width: '100%',
      height: '100%',
    },

    footerText: {
      color: '#f1f1f1',
      fontWeight: 600,
      fontSize: viewportType === 0 ? '13.25px' : '14px',
      fontFamily: 'Manrope',
      cursor: 'pointer',
    },

    desktopBackground: {
      backgroundColor: '#ffffff', 
      display: 'flex', 
      flexDirection: 'row', 
      width: '50%',
      justifyContent: 'center',
    },

    pcButtonText: {
      color: '#f1f1f1',
      fontFamily: 'Poppins',
      fontSize: viewportType === 0 ? '13.5px' : (viewportType === 1 ? '14px' : '14.5px'),
      fontWeight: 600,
    },
  
    pcButton: {
      backgroundColor: '#407c97',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: viewportType === 0 ? '30px' : (viewportType === 1 ? '33px' : '34px'),
      width: viewportType === 0 ? '126px' : (viewportType === 1 ? '158px' : '36%'),
      cursor: 'pointer',
    },
  }

  return (
      <div style={{
        height: viewportType === 0 ? '844px' : (viewportType === 1 ? '768px' : '602px'),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `linear-gradient(
        to top, 
        black 0%, 
        black 30%, 
        rgba(0, 0, 0, 0.20) 80%, 
        rgba(0, 0, 0, 0.6) 100%), 
        url(${BannerStatic})`,
      }}>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[140, 162, 140]} container={true} 
          style={{ 
            paddingTop: viewportType === 0 ? '28px' : (viewportType === 1 ? '40px' : '28px'),
            zIndex: 1,
          }}>

          <Flex col={[4, 8, 12]} span={[0, 0, 2]} />

          <Flex col={[4, 8, 12]} span={[2, 3, 3]} height={[100, 38, 35]}>
            <div style={{width: '100%'}}>
              <EcoLogo />
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 0, 1]}/>

          <Flex col={[4, 8, 12]} span={[2, 5, 4]} height={[100, 38, 35]} 
            style={{
              display: 'flex',
              flexDirection: viewportType === 0 ? 'column' : 'row',
              alignItems: viewportType === 0 ? 'flex-end' : 'center',
              justifyContent: viewportType === 0 ? 'flex-start' : 'flex-end',
            }}>
            <div 
            onClick={() => {window.location = process.env.REACT_APP_ECOSPHERE_FRONT}}
            style={{
              borderWidth: viewportType === 0 ? '3px' : '3.5px',
              borderImage: 'linear-gradient(to right, #97DBF8, #A8D25B) 1',
              borderStyle:'solid', 
              marginBottom: viewportType === 0 ? '12px': '0px',
              marginRight: viewportType === 0 ? '0px' : '20px',
              ...styles.landingPageButton,
            }}>
              Manage Subs
            </div>
            <div 
            onClick={() => window.location = 'https://www.youtube.com/@EcoAnalytics-EcoScan'}
            style={{
              backgroundColor: '#606060',
              ...styles.landingPageButton,
            }}>
              EcoLearning
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 0, 2]}/>

        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 7, 12]} height={[119, 110, 110]} container={true}>
          <Flex col={[4, 8, 12]} span={[0, 0, 2]}/>

          <Flex col={[4, 8, 12]} span={[4, 7, 7]} height={[99, 82, 82]}>
            <div style={{
              color: '#f1f1f1',
              fontWeight: 700,
              fontSize: viewportType === 0 ? '24px' : (viewportType === 1 ? '30px' : '31px'),
              fontFamily: 'Manrope',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              A Revolution in Ecological and Environmental Data Management
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]}/>
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 7, 8]} height={[136, 157, 138]} container={true}>

          <Flex col={[4, 8, 12]} span={[0, 0, 2]}/>

          <Flex col={[4, 8, 12]} span={[4, 6, 7]} height={[80, 69, 66]}>
            <div style={{
              color: '#f1f1f1',
              fontWeight: 500,
              fontSize: viewportType === 0 ? '15px' : (viewportType === 1 ? '17px' : '16px'),
              fontFamily: 'Manrope',
            }}>
              Empower researchers, policymakers, and private organizations by allowing them to make more informed decisions and take more effective action to address environmental challenges.
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 0, 2]}/>
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[358, 405, 434]} container={true}>

          <Flex col={[4, 8, 12]} span={[0, 0, 2]}/>

          <Flex col={[4, 8, 12]} span={[4, 8, 8]} height={[358, 405, 434]}>
            {viewportType === 0 ?
              <iframe width="100%" height="auto" 
                      src="https://www.youtube.com/embed/-tMICsXS-zI?loop=1&playlist=-tMICsXS-zI" 
                      title="YouTube video player" frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen /> :
              (viewportType === 1 ? 
              <iframe width="100%" height="auto" 
                      src="https://www.youtube.com/embed/-tMICsXS-zI?loop=1&playlist=-tMICsXS-zI" 
                      title="YouTube video player" frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen /> :
              <iframe width="100%" height="auto" 
                      src="https://www.youtube.com/embed/-tMICsXS-zI?loop=1&playlist=-tMICsXS-zI" 
                      title="YouTube video player" frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
            )}
          </Flex>


          <Flex col={[4, 8, 12]} span={[0, 0, 2]}/>

        </Flex>

        <Flex col={[0, 0, 12]} span={[0, 0, 0]} height={[88, 140, 44]}/>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[80, 45, 120]} container={true}>
          <Flex col={[4, 8, 12]} span={[0, 3, 5]}/>
          
          <Flex col={[4, 8, 12]} span={[4, 2, 2]} height={[80, 9, 8]} 
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div 
              onClick = {handleCentralizeDataScroll}
              targetID='centralize-data'
              style={{
                cursor: 'pointer',
              }}
              >
              <EcoDown />
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 3, 5]}/>
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[32, 44, 28]} container={true}>
          <div id='centralize-data'/>
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[55, 55, 55]} container={true}>
          <Flex col={[4, 8, 12]} span={[0, 2, 4]} />
          
          <Flex col={[4, 8, 12]} span={[4, 4, 4]} height={[27, 27, 27]} 
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
          }}>
            <GiCurledLeaf color='#98c93c' />
            <div
            style={{
              ...styles.blueText,
            }}>
              Centralize Data
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 2, 4]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[92, 88, 78]} container={true}>
          <Flex col={[4, 8, 12]} span={[0, 1, 3]} />
          
          <Flex col={[4, 8, 12]} span={[4, 6, 6]} height={[60, 30, 30]} 
            style={{
              display: 'flex',
              justifyContent: 'center',
          }}>
            <div style={{
              ...styles.sectionText,
            }}>
              Organize All Your Project Assets in One Place.
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 3]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[670, 837, 440]} container={true} 
          style={{
            backgroundImage: viewportType === 0 ? `url(${EcoBackground})` 
            : `url(${EcoIpadBackground})`,
            paddingTop: viewportType === 0 ? '16px': (viewportType === 1 ? '24px' : '16px'),
            paddingBottom: viewportType === 0 ?  '16px': (viewportType === 1 ? '24px' : '16px'),      
            display: 'flex',
            alignItems: 'center',
          }}>

          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[630, 797, 400]} 
            style={{
              display: 'flex',
              flexDirection: viewportType === 2 ? 'row' : 'column',
              justifyContent: 'space-evenly',
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: viewportType === 0 ? 'space-evenly' : 'center',
              paddingBottom: viewportType === 0 ? '16px': '36px',  
            }}>
              {viewportType === 0 ? <EcoMain1 /> : <EcoIpadMain1 />}
              <div style={{
                paddingTop: '8px',
                marginLeft: viewportType === 0 ? '0px' : (viewportType === 1 ? '48px' : '20px'),
              }}>
                {viewportType === 0 ? <EcoMain2 /> : <EcoIpadMain2 />}
              </div>
            </div>
            
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: viewportType === 2 ? '30px' : '0px',
            }}>
              <div style={{
                marginRight: viewportType === 0 ? '0px' : '16px',
              }}>
                {viewportType === 0 ? <EcoMain3 /> : <EcoIpadMain3 />}
              </div>
              {viewportType === 0 ? <div/> : <EcoIpadMain4 />}
            </div>

          </Flex>
        </Flex>

        <Controller>
        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[96, 116, 108]} container={true}
          style={{
            background: `linear-gradient(
            to bottom,
            black 0%,
            rgba(115,115,115, 0.2) 100%)`
          }}
        />

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={["110vh", "110vh", "100vh"]} container={true}
          style={{
            background: `linear-gradient(
            to bottom, 
            rgba(115,115,115, 0.2) 0%, 
            rgba(115,115,115, 0.42) 50%,
            rgba(115,115,115, 0.2) 80%),
            black 100%`,
          }}  
        >
          <Flex col={[4, 8, 12]} span={[0, 1, 2]} height={[50, "100vh", 50]} />

          <Flex col={[4, 8, 12]} span={[4, 6, 8]} height={[352, "100vh", 328]} 
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: viewportType === 1 ? '40px' : '0px',
            }}
          >
            <Scene triggerHook='onLeave' pin={true} duration={170}>
              {(progress) => (
                <div 
                  style={{
                    paddingTop: '20px', 
                    fontSize: viewportType === 0 ? '3vh' : viewportType === 1 ? '3.5vh' : '4vh', 
                    color: 'white',
                    textAlign: 'left'
                  }}
                >
                  {words.map((word, index) => (
                    <Tween to={{ opacity: progress >= (index + 3/3) / words.length ? 1 : 0.1 }} key={index}>
                      <span>{word + ' '}</span>
                    </Tween>
                  ))}
                </div>
              )}
            </Scene>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} height={[50, "10vh", 50]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[69, 57, 69]} container={true}>
          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={1}>
            <div style={{
              ...styles.line,
            }}/>
          </Flex>
        </Flex>
        </Controller>
        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[51, 51, 55]} container={true}>
          <Flex col={[4, 8, 12]} span={[0, 2, 4]} />

          <Flex col={[4, 8, 12]} span={[4, 4, 4]} height={27} 
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
          }}>
            <GiCurledLeaf color='#98c93c' />
            <div style={{
              ...styles.blueText,
            }}>
              Our Products
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 2, 4]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[84, 92, 62]} container={true}>
          <Flex col={[4, 8, 12]} span={[0, 1, 3]} />
          
          <Flex col={[4, 8, 12]} span={[4, 6, 6]} height={[60, 30]} 
            style={{
              display: 'flex',
              justifyContent: 'center',
          }}>
            <div style={{
              ...styles.sectionText,
            }}>
              Different Tools to Suit Different Needs.
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 3]} />
        </Flex>

        {appName[currentIndex] === 'ecoSphere' && (        
          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[764, 832, 440]} container={true}>
            <Flex col={[4, 8, 12]} span={[0, 1, 1]} />

            <div style={{
              display: 'flex',
              flexDirection: viewportType === 2 ? 'row' : 'column',
            }}>
              <ProductCard appName={'ecoSphere'} />
            </div>

            <Flex col={[4, 8, 12]} span={[0, 1, 1]} />
          </Flex>
        )}

        {appName[currentIndex] === 'ecoScan' && scanPrices ? (
          <PricingCard appName={'ecoScan'} setShowScanPrices={setShowScanPrices} setShowSnapPrices={setShowSnapPrices} setShowDocsPrices={setShowDocsPrices}/>
        )
          : appName[currentIndex] === 'ecoScan' && !scanPrices ? (

          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[764, 832, 440]} container={true}>
            <Flex col={[4, 8, 12]} span={[0, 1, 1]} />

            <div style={{
              display: 'flex',
              flexDirection: viewportType === 2 ? 'row' : 'column',
            }}>
              <ProductCard appName={'ecoScan'} setShowScanPrices={setShowScanPrices} />
            </div>

            <Flex col={[4, 8, 12]} span={[0, 1, 1]} />
          </Flex>
        ) : null}

        {appName[currentIndex] === 'ecoSnap' && snapPrices ? (
          <PricingCard appName={'ecoSnap'} setShowScanPrices={setShowScanPrices} setShowSnapPrices={setShowSnapPrices} setShowDocsPrices={setShowDocsPrices}/>
        )
          : appName[currentIndex] === 'ecoSnap' && !scanPrices ? (

          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[764, 832, 440]} container={true}>
            <Flex col={[4, 8, 12]} span={[0, 1, 1]} />

            <div style={{
              display: 'flex',
              flexDirection: viewportType === 2 ? 'row' : 'column',
            }}>
              <ProductCard appName={'ecoSnap'} setShowSnapPrices={setShowSnapPrices} />
            </div>

            <Flex col={[4, 8, 12]} span={[0, 1, 1]} />
          </Flex>
        ) : null}

        {appName[currentIndex] === 'ecoDocs' && docsPrices ? (
          <PricingCard appName={'ecoDocs'} setShowScanPrices={setShowScanPrices} setShowSnapPrices={setShowSnapPrices} setShowDocsPrices={setShowDocsPrices}/>
        )
        : appName[currentIndex] === 'ecoDocs' && !docsPrices ? (
          <>
            <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[335, 355, 440]} container={true}>
              <Flex col={[4, 8, 12]} span={[0, 1, 3]} />

              <Flex col={[4, 8, 12]} span={[4, 6, 6]} height={307}>
                <div style={{
                  backgroundColor: '#c0dfe2',
                  borderRadius: '10px',
                  paddingTop: viewportType === 0 ? '12px' : '16px',
                  paddingBottom: viewportType === 0 ? '12px' : '16px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingRight: '12px',
                  paddingLeft: '12px',
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    width: '100%',
                    borderRadius: '13px',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                  }}>
                    <div>
                      {viewportType === 0 ? <EcoDocsLogo  /> : <EcoDocsIpadLogo />}
                    </div>

                    <div style={{
                      ...styles.pcText,
                    }}>
                      Closed Alpha
                    </div>

                    <div style={{
                      color: '#000000',
                      fontFamily: 'Poppins',
                      fontSize: viewportType === 0 ? '20px' : '24px',
                      fontWeight: 300,
                      marginTop: '48px',
                    }}>
                      COMING SOON
                    </div>

                    <div
                      onClick={() => setShowDocsPrices(true)} 
                      targetID='docs-pricing'
                      style={{
                        marginTop: '20px',
                        ...styles.pcButton,
                      }}>
                      <div style={{
                        ...styles.pcButtonText,
                      }}>
                        Pricing
                      </div>
                    </div>
                  </div>

                </div>
              </Flex>

              <Flex col={[4, 8, 12]} span={[0, 1, 3]} />

            </Flex>
          </>
        ) : null}

        {viewportType === 2 ? 
        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[0, 0, 56]}/> :  <div/>
        }

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[100, 120, 100]} container={true}
          style={{
            paddingTop: '20px',
          }}
        >
         
          <Flex col={[4, 8, 12]} span={[1, 3, 5]} />

          <Flex col={[4, 8, 12]} span={[2, 2, 2]} height={[32, 36, 32]}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div 
            onClick={() => { 
              handleBack(); 
              setShowScanPrices(false); 
              setShowSnapPrices(false);
              setShowDocsPrices(false);
            }}
            style={{
              backgroundColor: '#ffffff',
              borderRadius: viewportType === 2 ? '7px' : '8px',
              borderColor: '#ababab',
              borderWidth: '1px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: viewportType === 0 ? '32px' : (viewportType === 1 ? '36px' : '32px'),
              height: viewportType === 0 ? '32px' : (viewportType === 1 ? '36px' : '32px'),
              cursor: 'pointer',
            }}>
              <EcoBackGray 
                height= {viewportType === 0 ? "16px" : (viewportType === 1 ?  "18px" : "16px")}
                width= {viewportType === 0 ? "8px" : (viewportType === 1 ? "9px" : "8px")}
                strokeWidth="2"/>
            </div>

            <div 
            onClick={() => {
              handleForward();
              setShowScanPrices(false); 
              setShowSnapPrices(false);
              setShowDocsPrices(false);
            }}
            style={{
              backgroundColor: '#ffffff',
              borderRadius: viewportType === 2 ? '7px' : '8px',
              borderColor: '#ababab',
              borderWidth: '1px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: viewportType === 0 ? '32px' : (viewportType === 1 ? '36px' : '32px'),
              height: viewportType === 0 ? '32px' : (viewportType === 1 ? '36px' : '32px'),
              marginLeft: '24px',
              cursor: 'pointer',
            }}>
              <EcoForwardGray 
                height= {viewportType === 0 ? "16px" : (viewportType === 1 ? "18px" : "16px")}
                width= {viewportType === 0 ? "8px" : (viewportType === 1 ? "9px" : "8px")}
                strokeWidth="2" 
              />
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[1, 3, 5]} />

        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={1} container={true}>
          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={1}>
            <div 
            id='contact-us'
            style={{
              ...styles.line,
            }}/>
          </Flex>
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[61, 77, 93]} container={true}
          style={{
            background: `linear-gradient(
            to bottom,
            black 0%,
            rgba(115,115,115, 0.2) 100%)`
          }}
        />

        <div 
          style={{
            background: `linear-gradient(
              to bottom, 
              rgba(115,115,115, 0.2) 0%, 
              rgba(115,115,115, 0.42) 50%,
              rgba(115,115,115, 0.2) 80%,
              black 100%)`,
          }}>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[63, 94, 104]} container={true} 
          style={{
            display: 'flex',
            flexDirection: 'row',
        }}>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />

          <Flex col={[4, 8, 12]} span={[2, 2, 2]} height={[27, 36, 36]}>
            <div style={{
              color: '#f1f1f1',
              fontWeight: 500,
              fontSize: viewportType === 0 ? '20px' : '24px',
              fontFamily: 'Manrope',
            }}>
              Lets Talk!
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 3, 5]} />
          
          <Flex col={[4, 8, 12]} span={[2, 1, 1]} height={[27, 28, 30]} 
            style={{
              justifyContent: 'flex-end',
            }}>
            <div               
              onClick={() => window.location = 'https://www.instagram.com/ecoanalyticsapp?igsh=MzRlODBiNWFlZA=='}
              style={{
                cursor: 'pointer',
                marginRight: viewportType === 0 ? '12px' : '16px',
              }}>
              <EcoInsta 
                height={viewportType === 0 ? '22px' : '28px'}
                width={viewportType === 0 ? '22px' : '28px'}
              />
            </div>

            <div 
              onClick={() => window.location = 'https://www.linkedin.com/company/ecoanalytics/'}
              style={{
                cursor: 'pointer',
              }}>
              <EcoLI 
                height={viewportType === 0 ? '22px' : '28px'}
                width={viewportType === 0 ? '22px' : '28px'}
              />
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[93, 111, 113]} container={true} 
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />

          <Flex col={[4, 8, 12]} span={[2, 3, 4]} height={[69, 75, 73]}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',  
            }}>
              <div style={{
                ...styles.inputLabel,
              }}>
                First Name&nbsp;
                <div style={{
                  color: '#CF6679',
                  fontWeight: 450,
                  fontSize: viewportType === 0 ? '14px' : '15px',
                  fontFamily: 'Manrope',
                }}>
                  *
                </div>
              </div>

              <div style={{
                ...styles.inputStyle,
                borderColor: isFirstNameFocused ? '#8ab8da' : 'transparent',
                borderWidth: isFirstNameFocused ? '2px' : '0px',
                borderStyle: isFirstNameFocused ? 'solid' : 'none',
              }}>
                <input
                  placeholder='First Name'
                  type='text'
                  style={{
                    ...styles.inputText,
                  }}
                  onFocus={() => setIsFirstNameFocused(true)}
                  onBlur={() => setIsFirstNameFocused(false)}
                  onChange={(e) => setFormFirstName(e.target.value)}
                />
              </div>
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[2, 3, 4]} height={[69, 75, 73]}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',  
            }}>
              <div style={{
                ...styles.inputLabel,
              }}>
                Last Name&nbsp;
                <div style={{
                  color: '#CF6679',
                  fontWeight: 450,
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                }}>
                  *
                </div>
              </div>

              <div style={{
                ...styles.inputStyle,
                borderColor: isLastNameFocused ? '#8ab8da' : 'transparent',
                borderWidth: isLastNameFocused ? '2px' : '0px',
                borderStyle: isLastNameFocused ? 'solid' : 'none',
              }}>
                <input
                  placeholder='Last Name'
                  type='text'
                  style={{
                    ...styles.inputText,
                  }}
                  onFocus={() => setIsLastNameFocused(true)}
                  onBlur={() => setIsLastNameFocused(false)}
                  onChange={(e) => setFormLastName(e.target.value)}
                />
              </div>
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />

        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[93, 111, 113]} container={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />

          <Flex col={[4, 8, 12]} span={[4, 6, 8]} height={[69, 75, 73]}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',  
            }}>
              <div style={{
                ...styles.inputLabel,
              }}>
                Organization&nbsp;
                <div style={{
                  color: '#CF6679',
                  fontWeight: 450,
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                }}>
                  *
                </div>
              </div>

              <div style={{
                ...styles.inputStyle,
                borderColor: isOrganizationFocused ? '#8ab8da' : 'transparent',
                borderWidth: isOrganizationFocused ? '2px' : '0px',
                borderStyle: isOrganizationFocused ? 'solid' : 'none',
              }}>
                <input
                  placeholder='Organization'
                  type='text'
                  style={{
                    ...styles.inputText,
                  }}
                  onFocus={() => setIsOrganizationFocused(true)}
                  onBlur={() => setIsOrganizationFocused(false)}
                  onChange={(e) => setFormCompany(e.target.value)}
                />
              </div>
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[93, 111, 113]} container={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />

          <Flex col={[4, 8, 12]} span={[4, 6, 8]} height={[69, 75, 73]}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',  
            }}>
              <div style={{
                ...styles.inputLabel,
              }}>
                Role&nbsp;
                <div style={{
                  color: '#CF6679',
                  fontWeight: 450,
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                }}>
                  *
                </div>
              </div>

              <div style={{
                ...styles.inputStyle,
                borderColor: isRoleFocused ? '#8ab8da' : 'transparent',
                borderWidth: isRoleFocused ? '2px' : '0px',
                borderStyle: isRoleFocused ? 'solid' : 'none',
              }}>
                <input
                  placeholder='Role'
                  type='text'
                  style={{
                    ...styles.inputText,
                  }}
                  onFocus={() => setIsRoleFocused(true)}
                  onBlur={() => setIsRoleFocused(false)}
                  onChange={(e) => setFormTitle(e.target.value)}
                />
              </div>
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />
        </Flex>

        <Flex col={[4, 8,  12]} span={[4, 8, 12]} height={[93, 111, 113]} container={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />

          <Flex col={[4, 8, 12]} span={[4, 6, 8]} height={[69, 75, 73]}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',  
            }}>
              <div style={{
                ...styles.inputLabel,
              }}>
                Email&nbsp;
                <div style={{
                  color: '#CF6679',
                  fontWeight: 450,
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                }}>
                  *
                </div>
              </div>

              <div style={{
                ...styles.inputStyle,
                borderColor: isEmailFocused ? '#8ab8da' : 'transparent',
                borderWidth: isEmailFocused ? '2px' : '0px',
                borderStyle: isEmailFocused ? 'solid' : 'none',
              }}>
                <input
                  placeholder='Email Address'
                  type='text'
                  style={{
                    ...styles.inputText,
                  }}
                  onFocus={() => setIsEmailFocused(true)}
                  onBlur={() => setIsEmailFocused(false)}
                  onChange={(e) => setFormEmail(e.target.value)}
                />
              </div>
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[201, 243, 219]} container={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />

          <Flex col={[4, 8, 12]} span={[4, 6, 8]} height={[153, 183, 167]}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',  
            }}>
              <div style={{
                ...styles.inputLabel,
              }}>
                How can we help?&nbsp;
              </div>

              <div style={{
                ...styles.inputStyle,
                height: viewportType === 0 ? '126px' : '150px',
                borderColor: isMessageFocused ? '#8ab8da' : 'transparent',
                borderWidth: isMessageFocused ? '2px' : '0px',
                borderStyle: isMessageFocused ? 'solid' : 'none',
              }}>
                <textarea
                  placeholder='Message'
                  className="input-placeholder"
                  style={{
                    ...styles.inputText,
                    boxSizing: 'border-box',
                  }}
                  onFocus={() => setIsMessageFocused(true)}
                  onBlur={() => setIsMessageFocused(false)}
                  onChange={(e) => setFormMessage(e.target.value)}
                />
              </div>
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 1, 2]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[127, 156, 120]} container={true}>
          <Flex col={[4, 8, 12]} span={[1, 1, 4]} />

          <Flex col={[4, 8, 12]} span={[2, 6, 4]} height={[39, 44, 40]} 
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#8AB8DA',
                borderRadius: '5px',
                width: viewportType === 0 ? '171px' : '255px',
                height: viewportType === 0 ? '39px' : (viewportType === 1 ? '44px' : '40px'),
                justifyContent: 'center',
              }}
              onClick={submitToSalesforce}
            >
              <EcoMessage />
              <div style={{
                color: '#f1f1f1',
                fontWeight: 600,
                fontSize: viewportType === 0 ? '14px' : '16px',
                fontFamily: 'Manrope',
                marginLeft: viewportType === 0 ? '12px' : '16px',
              }}>
                Send Message
              </div>
            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[1, 1, 4]} />
        </Flex>

        </div>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[163, 234, 285]} container={true} style={{
          backgroundImage: `url(${FooterBG})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover', // Or 'contain' based on your design
          backgroundRepeat: 'no-repeat',
        }}>
          <Flex col={[4, 8, 12]} span={[0, 2, 3]} />

          <Flex col={[4, 8, 12]} span={[4, 4, 6]} height={[123, 234, 285]}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
              height: '100%',
            }}>
              <div style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                textAlign: 'center',
              }}>
                <div style={{
                  color: '#f1f1f1',
                  fontWeight: 500,
                  fontSize: viewportType === 0 ?  '20px' : '22px',
                  fontFamily: 'Manrope',
                  marginBottom: viewportType === 0 ? '20px' : '24px',
                }}>
                  Learn more about ecoSnap!
                </div>
                
                <div 
                onClick={() => window.location = 'https://www.youtube.com/@EcoAnalytics-EcoScan'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#A9C7E1',
                  borderRadius: '4px',
                  width: viewportType === 0 ? '203px' : '208px',
                  height: viewportType === 0 ? '35px' : (viewportType === 1 ? '39px' : '37px'),
                  justifyContent: 'center',
                  color: '#222222',
                  fontWeight: 600,
                  fontSize: viewportType === 0 ? '13.5px' : '14px',
                  fontFamily: 'Manrope',
                  cursor: 'pointer',
                }}>
                  Learn More
                </div>
              </div>

            </div>
          </Flex>

          <Flex col={[4, 8, 12]} span={[0, 2, 3]} />
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={1} container={true}>
          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={1}>
            <div style={{
              ...styles.line,
            }}/>
          </Flex>
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[24, 24, 24]} container={true}/>
        
        <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[195, 68, 74]} container={true}>
          
          <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[53, 24, 29]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: viewportType === 0 ? 'column' : 'row',
              alignItems: viewportType === 2 ?  'center' : '',
            }}
          >
            <div style={{
              marginBottom: viewportType === 0 ? '32px' : '0px',
            }}>
              <EcoLogo 
                height= {viewportType === 0 ? "25px" :(viewportType === 1 ? "24px" : '29px')}
                width= {viewportType === 0 ? "171px" : (viewportType === 1 ? "162px" : '197px')}
              />
            </div>

          <div style={{
            display: 'flex',
            flexDirection: viewportType === 0 ? 'column' : 'row',
            justifyContent: 'flex-start',
          }}>
            <div 
              onClick = {() => {window.location = process.env.REACT_APP_ECOSPHERE_FRONT}}
              style={{
                ...styles.footerText,
                marginRight: '20px',  
                marginBottom: viewportType === 0 ? '20px' : '0px',
              }}>
                Manage Subscriptions
            </div>

            <div 
              onClick={handleContactScroll}
              targetID='contact-us'
              style={{
                ...styles.footerText,
                marginRight: '20px',  
                marginBottom: viewportType === 0 ? '20px' : '0px',
              }}>
                Contact Us
            </div>

            <div 
              onClick = {() => window.location = 'https://ecoanalytics.com/privacy'}
              style={{
                ...styles.footerText,
              }}>
                Privacy Policy
            </div>
          </div>

          </Flex>

        </Flex>     
      
        { viewportType === 0 ?
          <>
            <Flex col={4} span={4} height={49} container={true}>
              <Flex col={4} span={4} height={21} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                height: '100%',
                width: '100%',
              }}>
                <div>
                  <EcoNI />
                </div>

                <div style={{marginLeft: '20px'}}>
                  <EcoHR />
                </div>

                <div style={{marginLeft: '20px'}}>
                  <EcoWBENC />
                </div>
              </Flex>
            </Flex>

            <Flex col={4} span={4} height={52} container={true}>
              <Flex col={4} span={1} />

              <Flex col={4} span={2} height={20} style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <div 
                  onClick={() => window.location = 'https://www.instagram.com/ecoanalyticsapp?igsh=MzRlODBiNWFlZA=='}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <EcoInsta />
                </div>

                <div 
                  onClick={() => window.location = 'https://www.linkedin.com/company/ecoanalytics/'}
                  style={{
                    marginLeft: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <EcoLI />
                </div>

              </Flex>

              <Flex col={4} span={1} />
            </Flex>

            <Flex col={4} span={4} height={36} container={true}>
              <Flex col={4} span={4} height={16} style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <div style={{
                  color: '#ededed',
                  fontWeight: 400,
                  fontSize: viewportType === 0 ? '12px' : '13px',
                  fontFamily: 'Manrope',
                }}>
                  © 2024 EcoAnalytics™, Inc. All rights reserved.
                </div>

              </Flex>
            </Flex>
          </> 
          : 
          <Flex col={[0, 8, 12]} span={[0, 8, 12]} height={44} container={true}>
            <Flex col={[0, 8, 12]} span={[0, 8, 12]} height={16} style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <div style={{
                color: '#ededed',
                fontWeight: 400,
                fontSize: '12.5px',
                fontFamily: 'Manrope',
              }}>
                © 2024 EcoAnalytics™, Inc. All rights reserved.
              </div>

              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <div>
                  <EcoNI />
                </div>

                <div style={{marginLeft: viewportType === 2 ? '24px' : '16px'}}>
                  <EcoHR />
                </div>

                <div style={{
                  marginLeft: viewportType === 2 ? '24px' : '16px',
                  marginRight: '24px',
                }}>
                  <EcoWBENC />
                </div>

                <div 
                  onClick={() => window.location = 'https://www.instagram.com/ecoanalyticsapp?igsh=MzRlODBiNWFlZA=='}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <EcoInsta />
                </div>

                <div 
                  onClick={() => window.location = 'https://www.linkedin.com/company/ecoanalytics/'}
                  style={{
                    marginLeft: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <EcoLI />
                </div>

              </div>

            </Flex>
          </Flex>
        }
      </div>
  );
}

export default AppV2;