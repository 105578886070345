import React, { useContext } from 'react';
import Flex from './Flex';
import { AppContext } from '../context/AppContext';


import { ReactComponent as EcoBackGray } from '../assets/EcoBackGray.svg';


const PricingCard = ({ appName, setShowScanPrices, setShowSnapPrices, setShowDocsPrices }) => {
  const { viewportType } = useContext(AppContext);

  const styles = {
    pcBackButton: {
      borderWidth: '2px',
      borderColor: '#ccd0d1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'solid',
      backgroundColor: '#ffffff',
      borderRadius: '9999px',
      height: '42px',
      width: '42px',
    },
    tierCards: {
      borderRadius: viewportType === 0 ? '10px' : '9px',
      borderWidth: viewportType === 0 ? '3.75px' : '3px',
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
      backgroundColor: '#1e1e1e',
      width: '100%',
      height: viewportType === 0 ? '216px' : (viewportType === 1 ? '283px' : '288px'),
      borderStyle: 'solid',
    },

    tierHeaderText: {
      color: '#f1f1f1',
      fontFamily: 'Poppins',
      fontSize: viewportType === 0 ? '21px' : (viewportType === 1 ? '24px' : '23.5px'),
      fontWeight: 600,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'flex-start',
    },

    tierDescriptionText: {
      color: '#ededed',
      fontFamily: 'Poppins',
      fontStyle: 'italic',
      fontWeight: 500,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'flex-start',
    },

    tierLine: {
      borderBottomWidth: '0.5px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#717171',
      width: '100%',
      marginTop: '12px',
    },

    tierMonth: {
      color: '#ededed',
      fontFamily: 'Poppins',
      fontSize: viewportType === 2 ? '14px' : '12px',
      fontWeight: 600,
      textAlign: 'center',
      marginTop: '14px',
      display: 'flex',
      alignItems: 'center',
    },

    tierPrice: {
      color: '#ededed',
      fontFamily: 'Poppins',
      fontSize: viewportType === 2 ? '40px' : '36px',
      fontWeight: 600,
      textAlign: 'center',
      marginTop: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: viewportType === 2 ? 'row' : 'column',
    }}>
      <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[290, 303, 440]} container={true}
        style={{
          backgroundColor: '#e2eadf',
          borderRadius: viewportType === 2 ? '10px 0 0 10px' : '10px 10px 0 0',
          display: 'flex',
          flexDirection: (viewportType === 0 || viewportType === 2) ? 'column' : 'row',
          paddingTop: '20px',
          width: viewportType === 2 ? '33%' : '100%',
        }}
      >
        <Flex col={[4, 8, 12]} span={[1, 1, 1]} height={[54, 303, 54]}>
            <div 
              onClick={() => {
                setShowScanPrices(false);
                setShowSnapPrices(false);
                setShowDocsPrices(false);
              }}
              style={{
                ...styles.pcBackButton,
              }}
            >
              <EcoBackGray 
                height={20}
                width={10}
              />
            </div>
        </Flex>

        <Flex col={[4, 8, 12]} span={[4, 6, 12]} height={[227, 303, 440]}>
          <div style={{
            ...styles.tierCards,
            borderColor: '#94dbfa',
            display: 'flex',
            flexDirection: 'column',
            width: viewportType === 2 ? '32%' : '100%',
          }}>
            <div style={{
              ...styles.tierHeaderText,
            }}>
              Tier 1
            </div>

            <div style={{
              ...styles.tierDescriptionText,
              fontSize: viewportType === 0 ? '13px' : (viewportType === 1 ? '14px' : '14.25px'),
              marginTop: '4px',
            }}>
              For purchase of 1 license.
            </div>

            <div style={{
              ...styles.tierLine,
            }}/>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <div style={{
                ...styles.tierDescriptionText,
                fontSize: viewportType === 0 ? '14px' : (viewportType === 1 ? '15px' : '15px'),
              }}>
                Starting at
              </div>
              
              <div style={{
                ...styles.tierPrice,
              }}>
                {appName === 'ecoScan' ? '$49.99' : (appName === 'ecoSnap' ? '$9.99' : (appName === 'ecoDocs' ? '$19.99' : null))}
                <div style={{
                  ...styles.tierMonth,
                }}>
                  /mo
                </div>
              </div>
            </div>

            <div style={{
              ...styles.tierDescriptionText,
              fontSize: viewportType === 0 ? '12px' : '14px',
              marginTop: viewportType === 0 ? '24px' : '68px'
            }}>
              * Storage subject to additional cost.
            </div>
          </div>
        </Flex>
      </Flex>

      <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[235, 303, 440]} container={true}
        style={{
          backgroundColor: '#e2eadf',
          display: 'flex',
          paddingTop: '20px',
          flexDirection: (viewportType === 0 || viewportType === 2) ? 'column' : 'row',
          width: viewportType === 2 ? '33%' : 'auto',
        }}
      >
        {(viewportType === 2 || viewportType === 1) && (
          <Flex col={[4, 8, 12]} span={[0, 1, 1]} height={[54, 303, 54]} />
        )}

        <Flex col={[4, 8, 12]} span={[4, 6, 12]} height={[234, 303, 440]}>
          <div style={{
            ...styles.tierCards,
            borderColor: '#88cebb',
            display: 'flex',
            flexDirection: 'column',
            width: viewportType === 2 ? '32%' : '100%',
          }}>
            <div style={{
              ...styles.tierHeaderText,
            }}>
              Tier 2
            </div>

            <div style={{
              ...styles.tierDescriptionText,
              fontSize: viewportType === 0 ? '13px' : (viewportType === 1 ? '14px' : '14.25px'),
              marginTop: '4px',
            }}>
              For purchase of 2-5 licenses.
            </div>

            <div style={{
              ...styles.tierLine,
            }}/>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <div style={{
                ...styles.tierDescriptionText,
                fontSize: viewportType === 0 ? '14px' : (viewportType === 1 ? '15px' : '15px'),
              }}>
                Starting at
              </div>
              
              <div style={{
                ...styles.tierPrice,
              }}>
                {appName === 'ecoScan' ? '$69.99' : (appName === 'ecoSnap' ? '$19.99' : (appName === 'ecoDocs' ? '$29.99' : null))}
                <div style={{
                  ...styles.tierMonth,
                }}>
                  /mo
                </div>
              </div>
            </div>

            <div style={{
              ...styles.tierDescriptionText,
              fontSize: viewportType === 0 ? '12px' : '14px',
              marginTop: viewportType === 0 ? '24px' : '68px'
            }}>
              * Storage subject to additional cost.
            </div>
          </div>
        </Flex>
      </Flex>
        
      <Flex col={[4, 8, 12]} span={[4, 8, 12]} height={[256, 323, 440]} container={true}
        style={{
          backgroundColor: '#e2eadf',
          display: 'flex',
          flexDirection: (viewportType === 0 || viewportType === 2) ? 'column' : 'row',
          borderRadius: viewportType === 2 ? '0 10px 10px 0' : '0 0 10px 10px',
          paddingTop: '20px',
          paddingBottom: '20px',
          width: viewportType === 2 ? '34%' : '100%',
        }}
      >
        {(viewportType === 2 || viewportType === 1) && (
          <Flex col={[4, 8, 12]} span={[0, 1, 1]} height={[54, 303, 54]} />
        )}
        
        <Flex col={[4, 8, 12]} span={[4, 6, 12]} height={[235, 323, 440]}>
            <div style={{
              ...styles.tierCards,
              borderColor: '#b4d27b',
              display: 'flex',
              flexDirection: 'column',
              width: viewportType === 2 ? '32%' : '100%',
            }}>
              <div style={{
                ...styles.tierHeaderText,
              }}>
                Tier 3
              </div>

              <div style={{
                ...styles.tierDescriptionText,
                fontSize: viewportType === 0 ? '13px' : (viewportType === 1 ? '14px' : '14.25px'),
                marginTop: '4px',
              }}>
                For purchase of 6+ licenses.
              </div>

              <div style={{
                ...styles.tierLine,
              }}/>

              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <div style={{
                  ...styles.tierDescriptionText,
                  fontSize: viewportType === 0 ? '14px' : (viewportType === 1 ? '15px' : '15px'),
                }}>
                  Starting at
                </div>
                
                <div style={{
                  ...styles.tierPrice,
                }}>
                  {appName === 'ecoScan' ? '$99.99' : (appName === 'ecoSnap' ? '$49.99' : (appName === 'ecoDocs' ? '$49.99' : null))}
                  <div style={{
                    ...styles.tierMonth,
                  }}>
                    /mo
                  </div>
                </div>
              </div>

              <div style={{
                ...styles.tierDescriptionText,
                fontSize: viewportType === 0 ? '12px' : '14px',
                marginTop: viewportType === 0 ? '24px' : '68px'
              }}>
                * Storage subject to additional cost.
              </div>
            </div>
          </Flex>
      </Flex>
    </div>
  );
}

export default PricingCard;