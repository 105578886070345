import React, { useContext } from 'react';
import Flex from './Flex';
import { AppContext } from '../context/AppContext';

import { ReactComponent as EcoScanLogo } from '../assets/EcoScanLogo.svg';
import { ReactComponent as EcoScan } from '../assets/EcoScan.svg';
import { ReactComponent as EcoIpadScan } from '../assets/EcoIpadScan.svg';
import { ReactComponent as EcoScanIpadLogo } from '../assets/EcoScanIpadLogo.svg';

import { ReactComponent as EcoSnapLogo } from '../assets/EcoSnapLogo.svg';
import { ReactComponent as EcoSnap1 } from '../assets/EcoSnap1.svg';
import { ReactComponent as EcoSnap2 } from '../assets/EcoSnap2.svg';
import { ReactComponent as EcoSnap3 } from '../assets/EcoSnap3.svg';
import { ReactComponent as EcoSnap4 } from '../assets/EcoSnap4.svg';
import { ReactComponent as EcoSnapIpadLogo } from '../assets/EcoSnapIpadLogo.svg';
import { ReactComponent as EcoIpadSnap1 } from '../assets/EcoIpadSnap1.svg';
import { ReactComponent as EcoIpadSnap2 } from '../assets/EcoIpadSnap2.svg';
import { ReactComponent as EcoIpadSnap3 } from '../assets/EcoIpadSnap3.svg';
import { ReactComponent as EcoIpadSnap4 } from '../assets/EcoIpadSnap4.svg';

import { ReactComponent as EcoSphere1 } from '../assets/EcoSphere1.svg';
import { ReactComponent as EcoSphere2 } from '../assets/EcoSphere2.svg';
import { ReactComponent as EcoSphereIpad1 } from '../assets/EcoSphereIpad1.svg';
import { ReactComponent as EcoSphereIpad2 } from '../assets/EcoSphereIpad2.svg';
import { ReactComponent as EcoSphereLogo } from '../assets/EcoSphereLogo.svg';
import { ReactComponent as EcoSphereIpadLogo } from '../assets/EcoSphereIpadLogo.svg';


const ProductCard = ({ appName, setShowScanPrices, setShowSnapPrices, setShowDocsPrices }) => {
  const { viewportType } = useContext(AppContext);

  const styles = {
    pcText: {
      color: '#000000',
      fontFamily: 'Didact Gothic',
      fontSize: viewportType === 0 ? '15px' : (viewportType === 1 ? '16px' : '15.5px'),
      fontWeight: 300,
      marginTop: viewportType === 2 ? '28px' : '0px',
    },
  
    pcButtonText: {
      color: '#f1f1f1',
      fontFamily: 'Poppins',
      fontSize: viewportType === 0 ? '13.5px' : (viewportType === 1 ? '14px' : '14.5px'),
      fontWeight: 600,
    },
  
    pcButton: {
      backgroundColor: '#407c97',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: viewportType === 0 ? '30px' : (viewportType === 1 ? '33px' : '34px'),
      width: viewportType === 0 ? '126px' : (viewportType === 1 ? '158px' : '36%'),
      cursor: 'pointer',
    },
  };

  const EcoProductLogos = ({ appName }) => {
    if (appName === 'ecoSphere') {
        return <EcoSphereLogo />;
    } else if (appName === 'ecoScan') {
        return <EcoScanLogo />;
    } else if (appName === 'ecoSnap') {
        return <EcoSnapLogo height={50} width={155}/>;
    } else {
        return null;
    }
  };

  const EcoProductText = ({ appName }) => {
    if (appName === 'ecoSphere') {
        return (
          <>
            <p>
              ecoSphere™ is a robust environmental management application designed to streamline the organization and tracking of various assets related to environmental projects, from California Rapid Assessment Method (CRAM) assessments, photo stations, pertinent documentation, survey forms, and much more. The platform offers an effective way to integrate, manage and access a vast array of project-specific resources at a glance.
            </p>
            <p>
              Alongside asset management, ecoSphere™ also serves as a collaboration hub for project teams, facilitating efficient team management by keeping track of licenses, enhancing communication, and providing tools that foster seamless collaboration, ultimately propelling projects towards their successful completion.
            </p>
          </>
        );
    } else if (appName === 'ecoScan') {
        return (
          <>
            <p>
              ecoScan™ cuts hours off the current effective time it takes to assess a wetland area and is designed to evaluate the condition of the wetland based on its landscape setting, hydrology, physical structure, and biological structure.
            </p>
            <p>
              Because the methodology is standardized for over seven types of wetlands, ecological condition scores can be compared at the local, regional, and statewide landscape scales. It can also be used to assess the performance of compensatory mitigation projects and restoration projects.
            </p>
          </>
        );
    } else if (appName === 'ecoSnap') {
        return (
          <>
            <p>
              ecoSnap™ is a comprehensive application that empowers users to manage and chronicle project site photos over time by establishing a comprehensive photographic record of the site from all cardinal and subcardinal directions. Providing a unique and chronological view of specific locations, it allows for tracking changes in the environment or project progression in a highly organized manner.
            </p>
            <p>
              The app's innovative feature to generate 'photo plates,' which are PDF exports encapsulating collections of photo stations for specific projects, transforms the way one visualizes, shares, and understands spatial data over time, thereby proving invaluable for environmental studies, construction projects, and similar endeavours.
            </p>
          </>
        );
    } else {
        return null;
    }
  };

  const EcoSphereImages = () => {
    return (
      <>
        <div style={{
          marginRight: viewportType === 0 ? '0px' : (viewportType === 1 ? '40px' : '24px'),
        }}>
          {viewportType === 0 ? <EcoSphere1 /> : 
            (viewportType === 1 ? <EcoSphereIpad1 /> : 
            <EcoSphereIpad1 height={396} width={186}/>)
          }
        </div>

        {viewportType === 0 ? <EcoSphere2 /> : 
          (viewportType === 1 ? <EcoSphereIpad2 /> :
          <EcoSphereIpad2 height={396} width={150}/>)
        }
      </>
    );
  };

  const EcoScanImages = () => {
    return (
      <>
        {viewportType === 0 ? <EcoScan /> : <EcoIpadScan />}
      </>
    );
  };

  const EcoSnapImages = () => {
    return (
      <>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          {viewportType === 0 ? <EcoSnap1 /> : <EcoIpadSnap1 />}
          {viewportType === 0 ? <EcoSnap2 /> : <EcoIpadSnap2 />}
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: viewportType === 0 ? '0px' : '16px',
        }}>
          {viewportType === 0 ? <EcoSnap3 /> : <EcoIpadSnap3 />}
          {viewportType === 0 ? <EcoSnap4 /> : <EcoIpadSnap4 />}
        </div>
      </>
    );
  };

  const PricingButton = ({ appName, setShowScanPrices, setShowSnapPrices }) => {
    if (appName === 'ecoSphere') {
      return null;
    }
  
    const handlePricingButton = () => {
      if (appName === 'ecoScan') {
        setShowScanPrices(true);
      } else if (appName === 'ecoSnap') {
        setShowSnapPrices(true);
      }
    };
  
    return (
      <>
        <div 
          onClick={handlePricingButton}
          targetID='scan-pricing'
          style={{
            ...styles.pcButton,
          }}>
          <div style={{
            ...styles.pcButtonText,
          }}>
            Pricing
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Flex col={[4, 8, 12]} span={[4, 6, 5]} height={[354, 440, 440]}>
        <div style={{
          backgroundColor: appName === 'ecoSphere' || 'ecoSnap' ? '#e2eff6' : '#e2eadf',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: viewportType === 2 ? '0px' : '10px',
          borderBottomLeftRadius: viewportType === 2 ? '10px' : '0px',
          height: viewportType === 0 ? '354px' : '440px',
          width: '100%',
          display: 'flex',
          justifyContent: viewportType === 0 ? 'space-evenly' : 'center',
          alignItems: 'center',
          flexDirection: appName == 'ecoSnap' ? 'column' : 'row',
        }}>
          {/* {viewportType === 0 ? (appName == 'ecoScan' ? <EcoScan /> : appName == 'ecoSnap' ? <EcoSnap /> : null) : 
            (viewportType === 1 ? (appName == 'ecoScan' ? <EcoScanIpad /> : appName == 'ecoSnap' ? <EcoSnapIpad /> : null) :
            (appName == 'ecoScan' ? <EcoScanIpad height={415} width={392} /> : appName == 'ecoSnap' ? <EcoSnapIpad height={415} width={392}/> : null))
          } */}
          {appName == 'ecoSphere' ? <EcoSphereImages /> : appName == 'ecoScan' ? <EcoScanImages /> : <EcoSnapImages />}
        </div>
      </Flex>

      <Flex col={[4, 8, 12]} span={[4, 6, 5]} height={[422, 400, 440]}>
        <div style={{
          backgroundColor: '#ffffff',
          borderBottomLeftRadius: viewportType === 2 ? '0px' :'10px',
          borderBottomRightRadius: '10px',
          borderTopRightRadius: viewportType === 2 ? '10px' : '0px',
          height: viewportType === 0 ? '422px' : (viewportType === 1 ? '400px' : '440px'),
          width: '100%',
          paddingRight: viewportType === 0 ? '16px' : (viewportType === 1 ? '40px' : '24px'),
          paddingLeft: viewportType === 0 ?  '16px' : (viewportType === 1 ? '40px' : '24px'),
          paddingTop: viewportType === 0 ? '16px' : '20px',
          paddingBottom: viewportType === 0 ? '16px' : '20px',
          display: 'flex',
          justifyContent: 'space-evenly',
          flexDirection : 'column',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            {/* {viewportType === 0 ? (appName == 'ecoScan' ? <EcoScanLogo /> : appName == 'ecoSnap' ? <EcoSnapLogo /> : null) : 
              (viewportType === 1 ? (appName == 'ecoScan' ? <EcoScanIpadLogo /> : appName == 'ecoSnap' ? <EcoSnapIpadLogo /> : null) :
              (appName == 'ecoScan' ? <EcoScanIpadLogo height={57} width={200} /> : appName == 'ecoSnap' ? <EcoSnapIpadLogo height={57} width={200}/> : null))
            } */}
            <EcoProductLogos appName={appName} />
            <PricingButton appName={appName} setShowScanPrices={setShowScanPrices} setShowSnapPrices={setShowSnapPrices} />
          </div>

          <div style={{
            ...styles.pcText,
          }}>
            <EcoProductText appName={appName} />
          </div>
        </div>
      </Flex>
    </>
  );
};

export default ProductCard;